import React, { useRef } from "react";
function DeliveryPartner() {
    return (
        <>
            <section className="sec-pad">
                <div className="container">
                <div className="section-title text-center mb-5">
                            <h2>We Are Also Available  On </h2>
                        </div>
                    <div className="certificatebox overlay-zoom">
                        <img
                            src='/img/partner.jpg'
                            alt={'delivery partner'}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
export default DeliveryPartner;
